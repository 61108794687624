import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
    Button,
    Flex,
    FlexColumns,
    Input,
    Link,
    Tag,
    Typography,
    WelcomeContainer,
} from '@alphakits/ui/dist';
import { useApiMethod } from '@escapenavigator/services/dist/hooks';
import { InviteAfterVerificationDto } from '@escapenavigator/types/dist/profile/invite-after-verification.dto';
import { ProfileForVerificationRO } from '@escapenavigator/types/dist/profile/profile-for-verification.ro';
import { serializeRecord } from '@escapenavigator/utils/dist';
import { AuthForm } from 'src/components/form';
import { Head } from 'src/components/head';
import { Logo } from 'src/components/logo';
import image from 'src/images/login.webp';
import { useApi } from 'src/providers/api/context';

import { InviteSent } from './invite-sent';
import { View } from '.';

import styles from '../index.module.css';

type Props = {
    profile: ProfileForVerificationRO;
    setView: (value: View) => void;
    slug: string;
};

export const RequestInvite: React.FC<Props> = ({ profile, slug, setView }) => {
    const { users } = useApi();
    const { t } = useTranslation();
    const [email, setEmail] = useState('example');

    const {
        sendInviteAfterVerificationFetch,
        sendInviteAfterVerificationError,
        sendInviteAfterVerificationLoading,
        sendInviteAfterVerificationStatusOk,
    } = useApiMethod({
        api: users.sendInviteAfterVerification,
    });

    if (sendInviteAfterVerificationStatusOk) {
        return <InviteSent email={ email } />;
    }

    const domain =
        profile.site.split('//').length > 1
            ? profile.site.split('//')[1].split('/')[0]
            : profile.site.split('//')[0].split('/')[0];

    return (
        <WelcomeContainer image={ image } color="navigator">
            <Logo />
            <Head
                title={ t('Верификация профиля') }
                subtitle={
                    domain &&
                    t('Инструкции верификации', { domain }) +
                        (profile.emails.length ? ` ${t('Найденные email')}` : '')
                }
            />

            <AuthForm
                loading={ sendInviteAfterVerificationLoading }
                initialValues={ serializeRecord(InviteAfterVerificationDto, { slug }) }
                onSubmit={ (values) => {
                    sendInviteAfterVerificationFetch(values);
                    setEmail(values.email);
                } }
                buttonText={ t('Выслать приглашение') }
                error={ sendInviteAfterVerificationError?.message }
                t={ t }
            >
                { ({
                    values, touched, errors, setFieldValue,
                }) => (
                    <React.Fragment>
                        { !!profile.emails.length && (
                            <Flex direction="column" gap="sm" align="start" justify="start">
                                { profile.emails.map((email) => (
                                    <Tag key={ email } text={ email } view="primary" size="s" />
                                )) }
                            </Flex>
                        ) }

                        <FlexColumns columns={ 1 } gr={ 12 }>
                            <Input
                                value={ values.email }
                                onChange={ (e, { value }) => {
                                    setFieldValue('email', value);
                                    setFieldValue('selectedIndex', undefined);
                                } }
                                error={ touched.email && errors.email }
                                label={ t('forms.email') }
                                dataTestId="email"
                            />
                        </FlexColumns>
                    </React.Fragment>
                ) }
            </AuthForm>

            { +sendInviteAfterVerificationError?.code === 403 && (
                <Button view="link" size="xs" href="/password/recover">
                    Recover password
                </Button>
            ) }

            <Typography.Text className={ styles.polices } view="title" color="secondary">
                { t('Нет электронной почты с доменом') }{ ' ' }
                <Link className={ styles.link_primary } onClick={ () => setView('no-profile') }>
                    { t('allLinks.registration') }
                </Link>
            </Typography.Text>
        </WelcomeContainer>
    );
};
