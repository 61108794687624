/* eslint-disable complexity */
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CloseS, SearchM } from '@alphakits/icons';
import {
    Cell, Flex, IconButton, Image, InputAutocomplete, OptionShape,
} from '@alphakits/ui/dist';
import { useApiMethod } from '@escapenavigator/services/dist/hooks';
import { QuestroomByTitleRO } from '@escapenavigator/types/dist/questroom/questroom-by-title.ro';
import { CountriesEnum } from '@escapenavigator/types/dist/shared/enum/countries.enum';
import { useApi } from 'src/providers/api/context';

type Props = {
    onSelect: (slug?: string) => void;
    error?: string;
};

export const QuestroomsSearch: React.FC<Props> = ({ onSelect, error }) => {
    const api = useApi();
    const { t } = useTranslation();

    const [questrooms, setQuestrooms] = useState<QuestroomByTitleRO[]>([]);
    const [questroom, setQuestroom] = useState<QuestroomByTitleRO>();

    const { getByTitleFetch, getByTitleLoading } = useApiMethod({
        api: api.questrooms.getByTitle,
        successCallback: ({ data }) => setQuestrooms(data),
    });

    const [searchText, setSearchText] = useState('');

    const options: OptionShape[] = useMemo(
        () =>
            questrooms.map((c) => ({
                key: c.id,
                content: (
                    <Cell.Base
                        addon={ <Image key={ c.photo } src={ c.photo } /> }
                        title={ c.title }
                        subtitle={ c.address }
                    />
                ),
            })),
        [questrooms],
    );

    const handleChange = (id) => {
        const c = questrooms.filter((c) => c.id === id)[0];

        onSelect(c.profile.slug);
        setSearchText(c.title);
        setSearchText('');
    };

    const handleInputChange = (text) => {
        setSearchText(text);

        if (text && text.length > 3) {
            getByTitleFetch({
                title: text,
                country: CountriesEnum.USA,
            });
        }
    };

    if (questroom) {
        return (
            <Flex gap="md">
                <Cell.Base
                    addon={ <Image key={ questroom.photo } src={ questroom.photo } /> }
                    title={ questroom.title }
                    subtitle={ questroom.address }
                />

                <IconButton
                    onClick={ () => {
                        setQuestroom(undefined);
                        onSelect(undefined);
                    } }
                    icon={ CloseS }
                />
            </Flex>
        );
    }

    const getEmptyPlaceholder = () => {
        if (getByTitleLoading) return 'Loading';
        if (searchText.length < 4) return 'Minimun 4 letters';

        return 'Nothing found';
    };

    return (
        <React.Fragment>
            <InputAutocomplete
                options={ options }
                block={ true }
                size="s"
                showEmptyOptionsList={ true }
                optionsListProps={ { emptyPlaceholder: getEmptyPlaceholder() } }
                inputProps={ { leftAddons: <SearchM /> } }
                closeOnSelect={ true }
                hint={ t('Введите название квеста') }
                onInput={ (e) => {
                    handleInputChange(e.target.value);
                    setQuestrooms([]);
                } }
                onChange={ ({ selected }) => {
                    setQuestroom(questrooms.find((q) => q.id === selected.key));
                    handleChange(selected.key);
                    setQuestrooms([]);
                } }
                value={ searchText }
                error={ error }
            />
        </React.Fragment>
    );
};
