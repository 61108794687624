import React, { useEffect } from 'react';
import { Input, WelcomeContainer } from '@alphakits/ui/dist';
import { useApiMethod } from '@escapenavigator/services/dist/hooks';
import { LoginAdminDto } from '@escapenavigator/types/dist/admin/login-admin.dto';
import { serializeRecord } from '@escapenavigator/utils/dist';
import { AuthForm } from 'src/components/form';
import { Head } from 'src/components/head';
import { Preloader } from 'src/components/preloader';
import image from 'src/images/complete.webp';
import { useApi } from 'src/providers/api/context';
import { DefaultPageProps } from 'src/router/routes';

export const LoginAdmin: React.FC<DefaultPageProps> = ({ t }) => {
    const { admins } = useApi();

    const successCallback = () => {
        window.location.assign(process.env.REACT_APP_ADMIN_DOMAIN);
    };

    const { loginFetch, loginLoading, loginError } = useApiMethod({
        api: admins.login,
        successCallback,
    });

    const { meLoading, meFetch } = useApiMethod({ api: admins.me, successCallback });

    useEffect(() => {
        meFetch(undefined);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (meLoading) return <Preloader />;

    return (
        <WelcomeContainer image={ image } color="navigator">
            <Head title="Login for Escape Navigator Administrators" />

            <AuthForm
                initialValues={ serializeRecord(LoginAdminDto, {}) }
                onSubmit={ (data) => loginFetch({ data }) }
                buttonText="Login"
                error={ loginError && loginError.message }
                loading={ loginLoading }
                t={ t }
            >
                { ({
                    values, handleChange, touched, errors,
                }) => (
                    <React.Fragment>
                        <Input
                            value={ values.email }
                            onChange={ handleChange('email') }
                            error={ touched.email && errors.email }
                            label="Email"
                            autoFocus={ true }
                            required={ true }
                            dataTestId="email"
                        />
                        <Input
                            value={ values.password }
                            type="password"
                            onChange={ handleChange('password') }
                            error={ touched.password && errors.password }
                            label="Password"
                            required={ true }
                            dataTestId="password"
                        />
                    </React.Fragment>
                ) }
            </AuthForm>
        </WelcomeContainer>
    );
};
