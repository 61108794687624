import React, { Suspense } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Loader } from '@alphakits/ui/dist';
import { parse, stringify } from 'query-string';
import { QueryParamProvider } from 'use-query-params';
import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6';

import { RootRouter } from './router';

export const App = () => (
    <BrowserRouter>
        <Suspense fallback={ <Loader /> }>
            <QueryParamProvider
                adapter={ ReactRouter6Adapter }
                options={ {
                    searchStringToObject: parse,
                    objectToSearchString: stringify,
                } }
            >
                <RootRouter />
            </QueryParamProvider>
        </Suspense>
    </BrowserRouter>
);
