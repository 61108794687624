import React from 'react';
import ReactDOM from 'react-dom';
import { defaultThemes, THEME, ThemeProvider } from '@alphakits/ui';

import { ApiProvider } from './providers/api/context';
import { App } from './app';

import './index.css';
import '@alphakits/ui/dist/index.css';

ReactDOM.render(
    <React.StrictMode>
        <ThemeProvider selectedTheme={ THEME.LIGHT } themes={ defaultThemes }>
            <ApiProvider>
                <App />
            </ApiProvider>
        </ThemeProvider>
    </React.StrictMode>,
    document.getElementById('root'),
);
