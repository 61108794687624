import React from 'react';
import { TFunction } from 'i18next';
import { Invite } from 'src/pages/invite';
import { LoginAdmin } from 'src/pages/login-admin';
import { LoginQuestroom } from 'src/pages/login-questrooms';
import { RegistrationQuestrooms } from 'src/pages/registration-questrooms';
import { UserChangePassword } from 'src/pages/user-change-password';
import { UserRecoverPassword } from 'src/pages/user-recover-password';

export type DefaultPageProps = {
    t: TFunction;
    lang?: string;
};

export const routes = ({ t, lang }: DefaultPageProps) => [
    {
        path: '/auth/questrooms',
        element: <LoginQuestroom t={ t } lang={ lang } />,
    },
    {
        path: '/auth/invite',
        element: <Invite t={ t } lang={ lang } />,
    },
    {
        path: '/auth/admins',
        element: <LoginAdmin t={ t } lang={ lang } />,
    },
    {
        path: '/registration/questrooms',
        element: <RegistrationQuestrooms t={ t } lang={ lang } />,
    },
    {
        path: '/password/recover',
        element: <UserRecoverPassword t={ t } lang={ lang } />,
    },
    {
        path: '/password/change',
        element: <UserChangePassword t={ t } lang={ lang } />,
    },
];
