import React, { PropsWithChildren } from 'react';
import {
    Button, FlexColumns, ListAllert, TFunction,
} from '@alphakits/ui/dist';
import { validateByDto } from '@escapenavigator/utils/dist';
import { Formik, FormikProps, FormikValues } from 'formik';

import styles from 'src/pages/index.module.css';

type Props<T> = {
    initialValues: T;
    onSubmit: (values: FormikProps<T>['values']) => void;
    loading: boolean;
    error?: string;
    successText?: string;
    disabled?: boolean;
    buttonText: string;
    children: (props: FormikProps<FormikValues & T>) => React.ReactNode;
    bottomAddon?: React.ReactNode;
    t: TFunction;
    buttonDataTestId?: string;
};

export function AuthForm<T>({
    initialValues,
    buttonText,
    loading,
    onSubmit,
    disabled,
    bottomAddon,
    successText,
    t,
    children,
    error,
    buttonDataTestId,
}: PropsWithChildren<Props<T>>) {
    return (
        <div className={ styles.content }>
            <Formik initialValues={ initialValues } validate={ validateByDto(t) } onSubmit={ onSubmit }>
                { (props) => (
                    <FlexColumns columns={ 1 } gr={ 32 } gc={ 0 }>
                        <FlexColumns columns={ 1 } gr={ 20 } gc={ 0 }>
                            { children(props) }
                        </FlexColumns>

                        <FlexColumns columns={ 1 } gr={ 8 }>
                            { error && <ListAllert text={ error } view="negative" /> }

                            { successText && <ListAllert text={ successText } view="accent" /> }

                            <Button
                                onClick={ () => props.handleSubmit() }
                                block={ true }
                                className="brandBg"
                                loading={ loading }
                                disabled={ disabled }
                                view="primary"
                                size="m"
                                dataTestId={ buttonDataTestId }
                            >
                                { buttonText }
                            </Button>
                        </FlexColumns>

                        { bottomAddon }
                    </FlexColumns>
                ) }
            </Formik>
        </div>
    );
}
