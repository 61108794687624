import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import {
    Checkbox, Input, Typography, WelcomeContainer,
} from '@alphakits/ui/dist';
import { useApiMethod } from '@escapenavigator/services/dist/hooks';
import { LoginUserDto } from '@escapenavigator/types/dist/user/outside/login-user.dto';
import { serializeRecord } from '@escapenavigator/utils/dist';
import { AuthForm } from 'src/components/form';
import { Head } from 'src/components/head';
import { Logo } from 'src/components/logo';
import { Preloader } from 'src/components/preloader';
import image from 'src/images/login.webp';
import { useApi } from 'src/providers/api/context';
import { DefaultPageProps } from 'src/router/routes';

import styles from 'src/pages/index.module.css';

export const LoginQuestroom: React.FC<DefaultPageProps> = ({ t }) => {
    const { users } = useApi();

    const successCallback = () => {
        window.location.assign(process.env.REACT_APP_APP_DOMAIN);
    };

    const { loginFetch, loginLoading, loginError } = useApiMethod({
        api: users.login,
        successCallback,
    });

    const { meLoading, meFetch } = useApiMethod({ api: users.me, successCallback });

    useEffect(() => {
        meFetch(undefined);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (meLoading) return <Preloader />;

    return (
        <WelcomeContainer image={ image } color="default">
            <Logo />

            <Head title={ t('authQuestrooms.label') } />

            <AuthForm
                initialValues={ serializeRecord(LoginUserDto, { remember: true }) }
                onSubmit={ (data) => loginFetch({ data }) }
                buttonText={ t('authQuestrooms.button') }
                error={ loginError && loginError.message }
                loading={ loginLoading }
                buttonDataTestId="login"
                t={ t }
            >
                { ({
                    values, handleChange, touched, errors, setFieldValue,
                }) => (
                    <React.Fragment>
                        <Input
                            value={ values.email }
                            onChange={ handleChange('email') }
                            error={ touched.email && errors.email }
                            label={ t('auth:forms.email') }
                            required={ true }
                            autoFocus={ true }
                            dataTestId="email"
                        />
                        <Input
                            value={ values.password }
                            type="password"
                            onChange={ handleChange('password') }
                            error={ touched.password && errors.password }
                            label={ t('forms.password') }
                            required={ true }
                            dataTestId="password"
                        />

                        <div className={ styles.checkbox_wrap }>
                            <Checkbox
                                checked={ !values.remember }
                                onChange={ (e, { checked }: { checked: boolean }) =>
                                    setFieldValue('remember', checked) }
                                label={ t('authQuestrooms.checkbox') }
                                dataTestId="remember"
                            />
                            <Typography.Text color="accent" view="title">
                                <Link className={ styles.link_primary } to="/password/recover">
                                    { t('authQuestrooms.removeLink') }
                                </Link>
                            </Typography.Text>
                        </div>
                    </React.Fragment>
                ) }
            </AuthForm>

            <Typography.Text className={ styles.polices } view="title" color="secondary">
                { t('allLinks.stillNoAccount') }{ ' ' }
                <Link className={ styles.link_primary } to="/registration/questrooms">
                    { t('allLinks.registration') }
                </Link>
            </Typography.Text>
        </WelcomeContainer>
    );
};
