import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useRoutes } from 'react-router-dom';
import { EmptyPage } from '@alphakits/ui';
import i18next from 'i18next';
import { convertRoutes } from 'src/utils/convert-to-routes';

import { DefaultPageProps, routes } from './routes';

import '../locales/i18n';

const Routes = ({ t, lang }: DefaultPageProps) =>
    useRoutes([
        ...convertRoutes(routes({ t, lang })),
        {
            path: '*',
            element: (
                <EmptyPage
                    title="Wooops!"
                    code="404"
                    subtitle="Page not found"
                    buttonText="Go to main page"
                    href="/auth/questrooms"
                />
            ),
        },
        {
            path: '/',
            element: <React.Fragment />,
        },
    ]);

export const RootRouter = () => {
    const { t } = useTranslation();
    const lang = i18next.language;

    const navigate = useNavigate();
    const location = useLocation();

    const isHomePath = location.pathname === '/';

    useEffect(() => {
        if (isHomePath) {
            navigate('/auth/questrooms');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isHomePath]);

    return (
        <React.Fragment>
            <button
                type="button"
                onClick={ () => navigate('/auth/admins') }
                style={ {
                    opacity: 0,
                    position: 'fixed',
                    top: 0,
                    right: 0,
                    width: 10,
                    cursor: 'pointer',
                    height: 10,
                    zIndex: 2,
                } }
            >
                admin
            </button>
            <Routes t={ t } lang={ lang } />
        </React.Fragment>
    );
};
