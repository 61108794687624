/* eslint-disable no-plusplus */
import React from 'react';
import { Link } from 'react-router-dom';
import { Input, Typography, WelcomeContainer } from '@alphakits/ui/dist';
import { useApiMethod } from '@escapenavigator/services/dist/hooks';
import { RecoverPasswordDto } from '@escapenavigator/types/dist/user/outside/recover-password.dto';
import { convertMinutesToHHMM, serializeRecord } from '@escapenavigator/utils/dist';
import { AuthForm } from 'src/components/form';
import { useApi } from 'src/providers/api/context';
import { DefaultPageProps } from 'src/router/routes';

import { Head } from '../../components/head';
import image from '../../images/password.webp';

import { useTimer } from './use-timer';

import styles from '../index.module.css';

export const UserRecoverPassword: React.FC<DefaultPageProps> = ({ t }) => {
    const { users } = useApi();
    const {
        isActive, timer, startTimer, email,
    } = useTimer();

    const { sendRecoverLinkFetch, sendRecoverLinkLoading, sendRecoverLinkError } = useApiMethod({
        api: users.sendRecoverLink,
        successCallback: ({ data }) => {
            startTimer(data.email);
        },
    });

    return (
        <WelcomeContainer image={ image } color="navigator">
            <Head title={ t('passwordRestore.title') } subtitle={ t('passwordRestore.description') } />

            <AuthForm
                loading={ sendRecoverLinkLoading }
                disabled={ isActive }
                successText={ isActive && t('passwordRestore.allert', { email }) }
                initialValues={ serializeRecord(RecoverPasswordDto, {}) }
                onSubmit={ (values) => sendRecoverLinkFetch(values.email) }
                buttonText={
                    isActive
                        ? `${t('passwordRestore.button2')} ${convertMinutesToHHMM(timer)}`
                        : t('passwordRestore.button')
                }
                error={ sendRecoverLinkError?.message }
                t={ t }
            >
                { ({
                    handleChange, values, errors, touched,
                }) => (
                    <Input
                        value={ values.email }
                        onChange={ handleChange('email') }
                        error={ touched.email && errors.email }
                        label={ t('forms.email') }
                        required={ true }
                        dataTestId="email"
                    />
                ) }
            </AuthForm>

            <Typography.Text className={ styles.polices } view="title" color="secondary">
                <Link className={ styles.link_primary } to="/auth/questrooms">
                    { t('allLinks.login') }
                </Link>
            </Typography.Text>
        </WelcomeContainer>
    );
};
