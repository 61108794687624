import React from 'react';
import { useTranslation } from 'react-i18next';
import { WelcomeContainer } from '@alphakits/ui/dist';
import { Head } from 'src/components/head';
import successImage from 'src/images/complete.webp';

type Props = {
    email: string;
};

export const InviteSent: React.FC<Props> = ({ email }) => {
    const { t } = useTranslation();

    return (
        <WelcomeContainer color="navigator" image={ successImage }>
            <Head
                title={ t('Ссылка отправлена') }
                subtitle={ t('Ссылка отправлена описание', { email }) }
            />
        </WelcomeContainer>
    );
};
