import React, { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Input, Typography, WelcomeContainer } from '@alphakits/ui/dist';
import { useApiMethod } from '@escapenavigator/services/dist/hooks';
import { UpdateUserPasswordDto } from '@escapenavigator/types/dist/user/outside/update-user-password.dto';
import { serializeRecord } from '@escapenavigator/utils/dist';
import { StringParam, useQueryParam } from 'use-query-params';

import { Error } from '../../components/error';
import { AuthForm } from '../../components/form';
import { Head } from '../../components/head';
import { Preloader } from '../../components/preloader';
import errorImage from '../../images/404.webp';
import image from '../../images/password.webp';
import { useApi } from '../../providers/api/context';
import { DefaultPageProps } from '../../router/routes';

import styles from '../index.module.css';

export const UserChangePassword: React.FC<DefaultPageProps> = ({ t }) => {
    const navigate = useNavigate();

    const [token] = useQueryParam('token', StringParam);

    const { users } = useApi();

    const {
        checkTokenLoading, checkTokenError, checkTokenFetch, checkTokenData,
    } = useApiMethod({
        api: users.checkToken,
    });

    const { updatePasswordLoading, updatePasswordFetch, updatePasswordError } = useApiMethod({
        api: users.updatePassword,
        successCallback: () => window.location.assign(process.env.REACT_APP_APP_DOMAIN),
    });

    useEffect(() => {
        if (!token) return;

        checkTokenFetch({ token });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (checkTokenError) {
        return (
            <Error
                image={ errorImage }
                title={ t('error.oops') }
                description={ t('error.expiredLinkRecover') }
                buttonText={ t('error.expiredLinkButton') }
                onClick={ () => navigate('/password/recover') }
            />
        );
    }

    if (checkTokenLoading || !checkTokenData) return <Preloader />;

    return (
        <WelcomeContainer image={ image } color="navigator">
            <Head title={ t('passwordChange.title') } subtitle={ t('passwordChange.description') } />

            <AuthForm
                initialValues={ serializeRecord(UpdateUserPasswordDto, {}) }
                onSubmit={ (data) => updatePasswordFetch({ token, data }) }
                buttonText={ t('passwordChange.button') }
                error={ updatePasswordError && updatePasswordError.message }
                loading={ updatePasswordLoading }
                t={ t }
            >
                { ({
                    values, handleChange, touched, errors,
                }) => (
                    <React.Fragment>
                        <Input
                            value={ values.password }
                            type="password"
                            onChange={ handleChange('password') }
                            error={ touched.password && errors.password }
                            label={ t('forms.password') }
                            required={ true }
                            dataTestId="password"
                        />
                        <Input
                            value={ values.repeatPassword }
                            type="password"
                            onChange={ handleChange('repeatPassword') }
                            error={ touched.repeatPassword && errors.repeatPassword }
                            label={ t('forms.passwordRepeat') }
                            required={ true }
                            dataTestId="repeatPasword"
                        />
                    </React.Fragment>
                ) }
            </AuthForm>

            <Typography.Text className={ styles.polices } view="title" color="secondary">
                <Link className={ styles.link_primary } to="/auth/questrooms">
                    { t('allLinks.login') }
                </Link>
            </Typography.Text>
        </WelcomeContainer>
    );
};
