import React, { useEffect } from 'react';
import { Link, Typography, WelcomeContainer } from '@alphakits/ui/dist';
import { useApiMethod } from '@escapenavigator/services/dist/hooks';
import { ProfileForVerificationDto } from '@escapenavigator/types/dist/profile/profile-for-verification.dto';
import { serializeRecord } from '@escapenavigator/utils/dist';
import { AuthForm } from 'src/components/form';
import { Head } from 'src/components/head';
import { Logo } from 'src/components/logo';
import image from 'src/images/login.webp';
import { useApi } from 'src/providers/api/context';
import { DefaultPageProps } from 'src/router/routes';
import {
    createEnumParam, StringParam, useQueryParam, withDefault,
} from 'use-query-params';

import { QuestroomsSearch } from './client-search';
import { RequestCall } from './request-call';
import { RequestInvite } from './request-invite';

import styles from '../index.module.css';

export type View = 'find-profile' | 'no-profile';
type Type = 'questroom' | 'partner';

const ViewParam = withDefault<View, View>(
    createEnumParam(['find-profile', 'no-profile']),
    'find-profile',
);
const TypeParam = withDefault<Type, Type>(createEnumParam(['questroom', 'partner']), 'questroom');

export const RegistrationQuestrooms: React.FC<DefaultPageProps> = ({ t }) => {
    const [slug, setSlug] = useQueryParam('profile', StringParam);
    const [view, setView] = useQueryParam('view', ViewParam);
    const [type] = useQueryParam('type', TypeParam);

    const { profiles } = useApi();

    const {
        getBySlugForVerifyData: profile,
        getBySlugForVerifyError,
        getBySlugForVerifyFetch,
        getBySlugForVerifyLoading,
    } = useApiMethod({
        api: profiles.getBySlugForVerify,
    });

    useEffect(() => {
        if (slug) getBySlugForVerifyFetch({ slug });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [slug]);

    if (type === 'partner') return <RequestCall type="partner" />;

    if (view === 'find-profile' && profile && slug) {
        return <RequestInvite setView={ setView } profile={ profile } slug={ slug } />;
    }

    if (view === 'find-profile' && (!slug || !profile)) {
        return (
            <WelcomeContainer image={ image } color="navigator">
                <Logo />

                <Head title={ t('Поиск вашей организации') } subtitle={ t('Описание поиска') } />
                <AuthForm
                    loading={ getBySlugForVerifyLoading }
                    initialValues={ serializeRecord(ProfileForVerificationDto, {}) }
                    onSubmit={ (values) => setSlug(values.slug) }
                    buttonText={ t('Далее') }
                    error={ getBySlugForVerifyError?.message }
                    t={ t }
                >
                    { ({ setFieldValue, touched, errors }) => (
                        <QuestroomsSearch
                            onSelect={ (slug) => setFieldValue('slug', slug) }
                            error={ touched.slug && errors.slug }
                        />
                    ) }
                </AuthForm>

                <Typography.Text className={ styles.polices } view="title" color="secondary">
                    { t('Я не нашел квесты') }{ ' ' }
                    <Link className={ styles.link_primary } onClick={ () => setView('no-profile') }>
                        { t('allLinks.registration') }
                    </Link>
                </Typography.Text>
            </WelcomeContainer>
        );
    }

    return <RequestCall type="questroom" />;
};
