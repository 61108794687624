/* eslint-disable no-plusplus */
import { useEffect, useState } from 'react';

export const useTimer = () => {
    const [timer, setTimer] = useState(+localStorage.getItem('timerValue') || 60);
    const [email, setEmail] = useState<string>(localStorage.getItem('timerEmail'));
    const [isActive, setIsActive] = useState(localStorage.getItem('timerActive') === 'true');

    const decreaseTimer = () => {
        setTimer((prevTimer) => prevTimer - 1);
    };

    const startTimer = (email) => {
        localStorage.setItem('timerActive', 'true');
        localStorage.setItem('timerEmail', email);

        setEmail(email);
        setIsActive(true);
    };

    const stopTimer = () => {
        localStorage.setItem('timerActive', 'false');
        setIsActive(false);
    };

    useEffect(() => {
        let interval;

        if (isActive) {
            interval = setInterval(decreaseTimer, 1000);
        } else {
            clearInterval(interval);
        }

        if (timer <= 0) {
            stopTimer();
            setTimer(60);
            setEmail('');
            localStorage.setItem('timerEmail', '');
            localStorage.setItem('timerValue', `${60}`);
        }

        localStorage.setItem('timerValue', `${timer}`);

        return () => clearInterval(interval);
    }, [isActive, timer]);

    return {
        isActive,
        timer,
        startTimer,
        email,
    };
};
