import React from 'react';
import { FlexColumns, Typography } from '@alphakits/ui/dist';

type HeadProps = {
    title: string | React.ReactNode;
    subtitle?: string;
};

export const Head: React.FC<HeadProps> = ({ title, subtitle }) => (
    <FlexColumns columns={ 1 } gr={ 12 }>
        <Typography.Title weight="bold" tag="h1" view="xsmall" color="primary">
            { title }
        </Typography.Title>

        { subtitle && <Typography.Text view="primary-medium">{ subtitle }</Typography.Text> }
    </FlexColumns>
);
