import React, { useEffect } from 'react';
import { Loader } from '@alphakits/ui';
import { useApiMethod } from '@escapenavigator/services/dist/hooks';
import { StringParam, useQueryParam } from 'use-query-params';

import { Error } from '../../components/error';
import errorImage from '../../images/404.webp';
import { useApi } from '../../providers/api/context';
import { DefaultPageProps } from '../../router/routes';

export const Invite: React.FC<DefaultPageProps> = ({ t }) => {
    const [token] = useQueryParam('token', StringParam);

    const { users } = useApi();

    const { setCookieFetch, setCookieError } = useApiMethod({
        api: users.setCookie,
        successCallback: () => window.location.assign(process.env.REACT_APP_APP_DOMAIN),
    });

    useEffect(() => {
        if (!token) return;

        setCookieFetch({ token });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (setCookieError) {
        return (
            <Error
                image={ errorImage }
                title={ t('error.oops') }
                description={ t('error.expiredInviteRecover') }
            />
        );
    }

    return <Loader />;
};
